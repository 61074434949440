@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	font-size: 18px;
	font-family: "Roboto";
	font-weight: 300;
}

body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

@layer components {
	.px {
		@apply 2xl:px-80 xl:px-48 lg:px-32 md:px-gr px-kl;
	}
	.py {
		@apply md:py-gr py-mt;
	}
	.text-gr {
		@apply md:text-4xl text-2xl;
	}
	.text-mt {
		@apply md:text-xl text-lg;
	}
	b {
		@apply font-medium text-blue;
	}
	a {
		@apply italic;
	}
}
